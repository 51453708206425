.ModuleLink {
    position: relative;
    text-decoration: none;
    text-align: center;

    transition: filter ease-out 300ms;
    filter: none;
}

.ModuleLink.disabled {
    pointer-events: none;
    cursor: default;
}

.ModuleLink:hover {
    filter: drop-shadow(0 3.2px 7.2px rgba(0, 0, 0, 0.4)) drop-shadow(0 0.6px 1.8px rgba(0, 0, 0, 0.4));
    z-index: 100;
    border-width: 10px;
    border-color: white;
}

.ModuleLink-hex-outer {
    --hex-size: 120px;
    --hex-margin: 5px;

    color: transparent;
}

.ModuleLink:hover .ModuleLink-hex-outer {
    color: white;
}

.ModuleLink-hex-inner {
    --hex-size: 100px;
    --hex-margin: 0px;

    top: 7px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    position: absolute;
    left: 16px;
}

.ModuleLink-hex-outer,
.ModuleLink-hex-inner {
    width: var(--hex-size);
    margin: var(--hex-margin);
    height: calc(var(--hex-size) * 1.1547);
    font-size: initial;
    margin-block-end: calc(var(--hex-margin) - var(--hex-size) * 0.2886);
}
