.folder-section {
    position: relative;
}
.dataset-folder-name {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 11px;
    font-weight: 600;
    font-size: 14px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.folder-button {
    display: flex;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    font-family: "Baloo 2", sans-serif;
}

.dataset-folder-name .folder-radio {
    visibility: hidden;
    padding: 0;
    margin: 0;
}

.dataset-folder-name:hover .folder-radio {
    visibility: visible;
}

.dataset-folder-name:hover {
    background-color: rgb(243, 242, 241);
}
