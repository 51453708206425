.ModulePage {
    height: 100%;

    display: flex;
    flex-direction: column;
}

.ModulePage > div[role="tabpanel"] {
    flex: 1;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
