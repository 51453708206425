body {
    margin: 0;
    font-family: "Baloo 2", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow: hidden;
    /* Hide scrollbar for IE, Edge and Firefox */

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* removing arrow from number input field  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.PivotWrapper > div {
    flex: 1;

    display: flex;
    flex-direction: column;
}

.PivotWrapper div[role="tabpanel"] > div {
    height: 100%;
    position: relative;
}

.ms-Stack.equal-size {
    flex-basis: 0;
}

.css-jpln7h-MuiTabs-scroller {
    position: initial !important;
}

.flexContainer-124 {
    justify-content: flex-start !important;
}

.icon-158 {
    margin: 0 !important;
}

div.echarts-for-react div {
    z-index: 0 !important;
}

canvas[data-zr-dom-id="zr_0"] {
    z-index: 1 !important;
}

.csvLink {
    text-decoration: none;
    color: unset;
}

.center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.h100 {
    height: 100%;
}

.text-center {
    text-align: center;
}

.primaryColor {
    color: #ff8200;
}

.noMarginVer {
    margin-top: 0px;
    margin-bottom: 0px;
}
.noScrollbarAll {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
}

.noScrollbar::-webkit-scrollbar:vertical {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
}
.noScrollbar::-webkit-scrollbar-thumb:vertical {
    visibility: hidden !important;
}

.noScrollbar::-webkit-scrollbar:horizontal {
    height: 10px;
}

.chartWidth {
    width: 590px;
}

/* to make scrollbar appear at the top */
/* wrap container in .Flipped and child content with .Content */
.Flipped,
.Flipped .Content {
    transform: rotateX(180deg);
    -moz-transform: rotateX(180deg); /* Mozilla */
    -webkit-transform: rotateX(180deg); /* Safari and Chrome */
    -ms-transform: rotateX(180deg); /* IE 9+ */
    -o-transform: rotateX(180deg); /* Opera */
}

.Content {
    justify-content: end;
}

.br-format {
    br {
        display: block; /* makes it have a width */
        content: ""; /* clears default height */
        margin-top: 10px; /* change this to whatever height you want it */
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

/* checkbox react grid styling */

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input:checked ~ span {
    background-color: #ff8200;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label {
    height: 20px;
    width: 20px;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell span {
    height: 20px;
    width: 20px;
}
