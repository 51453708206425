.LoadingBlockerContainer {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 1001;
    align-items: center;
    justify-content: center;
    display: flex;
}

.LoadingBlockerOpacityBackground {
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-color: white;
    opacity: 0.7;
    z-index: 1;
}

.LoadingBlockerContainer h3 {
    color: rgb(242, 143, 29);
    z-index: 2;
}

.LoadingBlockerTextContainer {
    z-index: 2;
    padding-left: 10px;
    padding-right: 10px;

    h3 {
        text-align: center;
    }
}
