.GazAutoRTAParameterTable .ms-DetailsRow-cell[role="gridcell"] {
    text-align: center;
}
.GazAutoRTAParameterTable .ms-DetailsHeader-cell[data-item-key="value"] .ms-DetailsHeader-cellTitle {
    text-align: center;
    justify-content: center;
}
.GazAutoRTAParameterTable .ms-DetailsHeader-cell[data-item-key="units"] .ms-DetailsHeader-cellTitle {
    text-align: center;
    justify-content: center;
}

.reactgrid-content .rg-pane .rg-cell.rg-cell-group-border {
    border-top: 0 !important;
    border-bottom: 0 !important;
}

.reactgrid-content .rg-pane.rg-pane-top {
    z-index: 1 !important;
}

.chartWrapper {
    height: 480px;
    min-width: 590px;
    padding: 0;
    display: flex;
    justify-content: center;
}

.oilPvtDataTableWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 15px;
    height: 400px;
    width: 610px;
    padding-top: 20px;
}

.dataTableWrapper {
    position: relative;
    height: 400px !important;
    width: 590px;
    padding: 0;
    display: flex;
    justify-content: center;
}

.dataViewTableTab {
    position: relative;
    height: 95%;
}

.dataViewTableStyle {
    position: absolute;
    top: 20px;
    bottom: 0;
    overflow: scroll;
}

.overlay {
    position: absolute;
    width: 120%;
    height: 100%;
    background: lightgrey;
    opacity: 0.3;
    border-radius: 5px;
    left: 0px;
    top: 0px;
    bottom: 0px;
}
